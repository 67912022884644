.label-matched {
  .matched-title {
    text-align: center;
    margin-top: 40%;
    font-weight: 900;
    color: var(--matched-text-color);
    font-style: italic;
    letter-spacing: -0.5px;

    .text-its {
      font-size: 20px;
    }

    .text-match {
      font-size: 60px;

      div:nth-child(2), div:nth-child(3) {
        color: transparent;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: var(--matched-text-color);
        clip-path: inset(34px 0 0 0);
        opacity: .6;
        position: relative;
        margin-top: -50px;
        left: -4px;
      }

      div:nth-child(3) {
        opacity: .2;
        margin-top: -50px;
        left: -8px;
      }
    }
  }
}
