/*
  CSS Helper v2.0.1 (23/11/2020)
  Hieu Pham <mr_hie@yahoo.com>
*/

.toolbar-no-border {
  --border-width: 0 !important;
}

.toolbar-transparent {
  --min-height: 0;
  --border-width: 0 !important;
}

.toolbar-no-safe-area {
  --ion-safe-area-top: 0;
  --ion-safe-area-bottom: 0;
  --device-safe-area: env(safe-area-inset-bottom);
}

.safe-area-bottom {
  padding-bottom: var(--ion-safe-area-bottom) !important;
}

.tab-bar-no-border {
  --border: none;
}

ion-avatar, ion-thumbnail {
  &.thumbnail-xs {
    width: 24px;
    height: 24px;
  }

  &.thumbnail-sm {
    width: 32px;
    height: 32px;
  }

  &.thumbnail-md {
    width: 40px;
    height: 40px;
  }

  &.thumbnail-lg {
    width: 64px;
    height: 64px;
  }

  &.thumbnail-xl {
    width: 96px;
    height: 96px;
  }

  &.thumbnail-xxl {
    width: 128px;
    height: 128px;
  }
}

.avatar {
  position: relative;

  &.has-border {
    border: 2px solid var(--ion-color-primary);
    padding: 2px;
  }
}

.avatar-status {
  position: absolute;
  right: 0;
  bottom: 0;
}

.online-status {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--ion-item-background);
  vertical-align: middle;
  background-color: var(--ion-color-medium);

  &.active {
    background-color: var(--ion-color-success);
  }

  &.no-border {
    border: none;
  }
}

.modal-custom-half {
  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1;
  }

  .modal-wrapper {
    margin-top: auto;
    height: 24em;
    border-radius: 16px 16px 0 0;
  }
}

.hash-tag {
  text-decoration: none;
  margin-right: 4px;
  display: inline-block;
}

.grid-no-padding {
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;
}

.bg-light {
  --background: var(--ion-color-light);
}

.bg-transparent {
  --background: transparent;
}

.button-no-padding {
  --padding-start: 0;
  --padding-end: 0;
}

.background-img {
  background-position: center center;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100px;

  &.square {
    height: 0px;
    padding-bottom: 100%;
  }
}

.text-ellipsis {
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.scroll-horizontal {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  .scroll-item {
    position: relative;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

.vertical-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* UTILITIES */
.text-underline {
  text-decoration: underline;
}

.text-sm {
  font-size: var(--font-size-sm, 13px);
}

.inline {
  display: inline-block;
}

.full-height {
  height: 100%;
}
