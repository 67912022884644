.matches-page {
  --border-color-rgb: rgba(var(--ion-color-medium-rgb), 0.1);

  .border-bottom {
    border-bottom: 1px solid var(--border-color-rgb);
  }

  .matches-list {
    white-space: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    height: 112px;

    .matches-item {
      display: inline-block;
      width: 64px;
      margin-right: 12px;
      position: relative;
      margin-bottom: 12px;

      .profile-image {
        height: 64px;
        position: relative;
        margin-bottom: 8px;
      }

      .online-status {
        background-color: var(--ion-color-success);
        position: absolute;
        right: -8px;
        top: 26px;
        width: 18px;
        height: 18px;
        border-radius: 999em;
        border: 3px solid #fff;
      }

      .like-gold {
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 50%;
        text-align: center;
        padding-top: 2px;

        ion-icon {
          color: var(--ion-color-gold);
        }
      }

      .scroll-item-title {
        text-align: center;
        font-size: var(--font-size-sm);
        font-weight: 700;
      }

      &:first-child {
        margin-left: 12px;
      }

      &.matches-item-likes {
        .profile-image {
          border: 2px solid var(--ion-color-gold);
          border-radius: 999em;
          padding: 2px;
          margin-bottom: 6px;
        }

        .profile-image-inner {
          overflow: hidden;
          border-radius: 999em;
          height: 56px;
          display: block;
        }

        random-avatar {
          filter: blur(2px);
        }

        .likes-count {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
          background-color: var(--ion-color-gold);
          color: white;
          text-align: center;
          font-size: 12px;
          font-weight: 700;
          padding-top: 6px;
          border-radius: 999em;
        }
      }
    }
  }

  .message-list {
    padding: 6px;

    .message-item-text {
      padding: 16px;
      border-bottom: 1px solid var(--border-color-rgb);

      .user-name {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 4px;
      }
    }
  }

  .feed-item {
    .feed-item-cover {
      position: relative;

      .background-img {
        height: 0px;
        padding-bottom: 125%;
      }
    }

    .feed-item-header {
      padding: 4px 10px;
    }

    .user-name {
      font-weight: 700;
    }

    .secondary-info {
      font-size: var(--font-size-sm);
      color: var(--ion-color-medium);
    }

    .matched-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 10%, rgba(40, 50, 60, 0.1) 50%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 24px;
    }

    .feed-item-footer {
      position: relative;
      padding: 16px 92px 16px 16px;
    }

    .feed-item-buttons {
      position: absolute;
      right: 0;
      top: -18px;
      text-align: right;
      display: inline-block;
      padding: 0 8px;

      ion-button {
        margin: 0 4px;
      }
    }

    .profile-user-info {
      line-height: 1.4em;
      color: var(--ion-color-medium);

      .info-item {
        position: relative;
        padding-left: 24px;

        ion-icon {
          position: absolute;
          top: 4px;
          left: 4px;
        }
      }
    }

    ion-icon {
      &.color-green {
        color: var(--color-button-like);
      }
      &.color-blue {
        color: var(--color-button-star);
      }
    }
  }

  .list-header {
    padding: 16px;
  }
}
