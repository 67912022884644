/* Chat UI v2.1.0 */

.chat-list {
  .chat-timestamp {
    font-size: 12px;
    color: var(--ion-color-medium);

    &.center {
      text-align: center;
      margin: 8px 0;
    }
  }

  .chat-item {
    margin-bottom: 16px;

    &.chat-item-outgoing {
      text-align: right;

      .chat-item-inner {
        flex-direction: row-reverse;
      }

      .chat-item-bubble .chat-text {
        border-radius: 20px 20px 0 20px;
        background: var(--ion-color-secondary);
        color: #fff;
      }
    }

    .chat-item-inner {
      display: flex;
      align-items: flex-end;
    }

    .chat-avatar {
      align-self: flex-end;
      margin-right: 8px;
      width: 40px;
      height: 40px;

      img {
        display: inline-block;
        border-radius: 50%;
      }
    }

    .chat-body {
      max-width: 75%;
    }

    .chat-item-status {
      font-size: 12px;
      color: var(--ion-color-medium);

      ion-icon {
        position: relative;
        top: 2px;
      }
    }

    .chat-item-bubble {
      position: relative;

      .chat-text {
        display: inline-block;
        padding: 12px 16px;
        white-space: normal;
        max-width: 100%;
        text-align: left;
        border-radius: 20px 20px 20px 0;
        background: var(--ion-color-light);

        img {
          width: 100%;
          max-width: 480px;
          border-radius: 16px;
        }
      }

      &.bubble-image {
        font-size: 0;
        border-radius: 20px;
        overflow: hidden;
      }
    }

    .chat-item-reaction {
      margin-left: 8px;
      font-size: 24px;
      line-height: 18px;
      color: var(--ion-color-medium);
      align-self: center;
    }
  }
}
