/**
  Dark Mode for Ionic 4
  v1.1
  Hieu Pham (https://www.takethatdesign.com)
*/

/* Dark theme (https://material.io/design/color/dark-theme.html#ui-application) */;
$darkBgColor: #121212;
$darkOverlayColor: #ffffff;
$darkSurfaceLv1Color: mix($darkOverlayColor, $darkBgColor, 5%); // #1e1e1e; // 01dp - e.g Navbar, card
$darkSurfaceLv2Color: mix($darkOverlayColor, $darkBgColor, 8%); // #242424; // 03dp - e.g Dropdown menu
$darkSurfaceLv3Color: mix($darkOverlayColor, $darkBgColor, 11%); // #2c2c2c; // 06dp - e.g Modal panel
$darkSurfaceLv4Color: mix($darkOverlayColor, $darkBgColor, 14%);

/** Material Dark UI colors **/
:root {
  --dark-color-background: #{$darkBgColor};
  --dark-color-header: #{$darkSurfaceLv2Color};
  --dark-color-foreground: #{$darkSurfaceLv1Color};
  --dark-color-button: #{$darkSurfaceLv4Color};
}

body.dark-theme {
  /* dark mode variables go here */
  --ion-color-primary: #fd5068;
  --ion-color-primary-rgb: 253,80,104;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #df465c;
  --ion-color-primary-tint: #fd6277;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80,200,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #303030;
  --ion-color-light-rgb: 48,48,48;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #2a2a2a;
  --ion-color-light-tint: #454545;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark-theme {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0,0,0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #1c1c1c;
  --ion-item-background-activated: #313131;
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark-theme {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;
}

/*
 * My Custom Dark Theme
 * -------------------------------------------
 */

body.dark-theme {
  /** white **/
  --ion-color-white: #000000;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #000000;
  --ion-color-white-tint: #000000;

  --ion-text-color: #e3e3e3;
  --ion-text-color-rgb: 227, 227, 227;
  --card-box-shadow: 0 2px 10px 0 rgba(19, 19, 19, 0.77) !important;

  ion-list-header {
    --background: var(--dark-color-background);
  }

  .modal-wrapper {
    --box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.77);
  }

  /** TINDER SPECIFIC **/
  .landing-page ion-button {
    --ion-color-white: var(--ion-color-dark) !important;
  }

  .header-custom {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14) !important;
  }

  .button-custom {
    --box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14) !important;

    &.ion-color-white {
      --ion-color-base: var(--dark-color-button);
      --ion-color-white-tint: #{$darkSurfaceLv3Color};
    }

  }

  .settings-page .card-custom {
    background-color: var(--ion-item-background);
  }

  .bg-light {
    --background: var(--dark-color-background);
  }

  app-me ion-toolbar {
    --ion-toolbar-background: var(--dark-color-foreground) !important;
  }

  .me-page {
    .section-upper {
      background-image: linear-gradient(to bottom, var(--ion-tab-bar-background,var(--ion-background-color,#fff)), var(--dark-color-foreground)) !important;
    }

    .has-oval:before {
      background-color: var(--dark-color-foreground) !important;
      box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.14) !important;
    }

    .section-lower {
      background-color: var(--dark-color-background) !important;
    }
  }

  .profile-page {
    --background: var(--ion-color-light);
  }

  app-explore ion-toolbar {
    --ion-toolbar-background: var(--ion-color-white) !important;
  }

  .explore-bg {
    --background: linear-gradient(var(--ion-color-white), var(--ion-color-step-100));
  }

  /* CHAT */
  .chat-list .chat-item:not(.chat-item-sender) {
    .chat-item-bubble {
      .chat-body {
        background: var(--dark-color-foreground) !important;
      }
    }
  }

  app-chat {
    ion-textarea, ion-input {
      --background: var(--dark-color-foreground) !important;
    }
  }

  gif-search {
    .giphy-container {
      background-color: var(--dark-color-background) !important;
    }
  }
}
